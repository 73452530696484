<template>
  <transition
    :duration="400"
    name="app-modal-wrapper"
    @after-enter="$emit('onTransitionAfterEnter')"
  >
    <div
      v-if="visible"
      class="fixed inset-0 flex justify-center items-center z-40 overflow-hidde"
      :class="{ 'bg-black bg-opacity-50': !mobileFullScreen }"
      :aria-expanded="visible"
      :data-modal="name"
      :cy-data="cyData"
      @mousedown.stop="handleClickOverlay"
    >
      <div
        class="flex items-center justify-center max-w-full h-full min-w-[24rem]"
        :class="!mobileFullScreen ? 'p-4' : undefined"
      >
        <div
          class="flex max-h-full overflow-hidden md:pt-14 md:pb-14 relative bg-white inner-modal"
          :class="modalClasses"
          :style="modalStyles"
          @mousedown.stop
        >
          <slot name="header">
            <AppButton
              icon
              round
              flat
              size="big"
              prefix-icon="com:close-thin"
              prefix-icon-class="!size-4 md:!size-5"
              class="absolute -top-1 -right-1 md:top-3 md:right-3 z-30"
              :class="closeClass"
              :cy-data="closeButtonCyData"
              @click="close(true)"
            />
          </slot>

          <div
            :class="{
              'px-4 md:px-10 overflow-y-auto': !mobileFullScreen
            }"
            class="w-full"
          >
            <slot />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style>
.app-modal-wrapper-enter-active,
.app-modal-wrapper-leave-active {
  transition: all 0.3s ease;
}

.app-modal-wrapper-leave-active {
  transition-delay: 0.1s;
}

.app-modal-wrapper-enter-from,
.app-modal-wrapper-leave-to {
  opacity: 0;
}

.app-modal-wrapper-enter-active .inner-modal,
.app-modal-wrapper-leave-active .inner-modal {
  transition: all 0.3s ease;
}

.app-modal-wrapper-enter-active .inner-modal {
  transition-delay: 0.1s;
}

.app-modal-wrapper-enter-from .inner-modal,
.app-modal-wrapper-leave-to .inner-modal {
  transform: translateY(20px) scale(0.85);
  opacity: 0;
}
</style>

<script setup lang="ts">
import {
  ref,
  computed,
  onMounted,
  watch,
  getCurrentInstance,
  provide,
  toRef
} from 'vue'
import { useEventListener } from '../composables/useEventListener'
import AppButton from '../components/AppButton.vue'
import { useRoute } from 'vue-router'
import type { IAppModalEmitsCommon, IAppModalProps } from '../models/Modal'

const props = withDefaults(defineProps<IAppModalProps>(), {
  show: false,
  closeOnOverlay: true,
  height: 'auto',
  width: 'auto'
})

const emit = defineEmits<
  {
    show: [props: IAppModalProps]
    onTransitionAfterEnter: []
  } & IAppModalEmitsCommon
>()

/* ------------------------------------------------------------------------------------------------------------------ */

const visible = ref(props.show)
const instance = getCurrentInstance()
const route = useRoute()

watch(
  () => props.show,
  () => {
    visible.value = props.show
  }
)

const modalStyles = computed(() => {
  if (props.contentClass) {
    return
  }

  return {
    width: props.width,
    height: props.height
  }
})

const modalClasses = computed(() => {
  return [
    props.contentClass,
    {
      '!px-0': props.cleanX,
      '!py-0': props.cleanY,
      'rounded-3xl md:rounded-5xl pt-8 pb-5': !props.mobileFullScreen
    }
  ]
})

const close = (isCloseBtn?: boolean) => {
  if (!visible.value) {
    return
  }

  visible.value = false
  emit('close', isCloseBtn)
  instance?.parent?.emit('close', isCloseBtn)
}

watch(toRef(route, 'path'), () => {
  if (!props.closeIfChangeRoute) return

  close()
})

const handleEsc = (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    close()
  }
}

useEventListener(document, 'keydown', handleEsc as (e: Event) => void)

const handleClickOverlay = () => {
  if (props.closeOnOverlay) {
    close()
  }
}

onMounted(() => {
  visible.value = true
})

provide('AppModalClose', close)

defineExpose({
  close
})
</script>
